
import { defineComponent } from 'vue';
import Breadcrumb from '@/views/new-design/layout/Breadcrumb.vue';
import {
  Actions as PromoActions,
  Getters as PromoGetters,
} from '@/store/enums/PromoEnums';
import { mapActions, mapGetters } from 'vuex';

import { acronym } from '@/utils/text';
import useBreakpoints from 'vue-next-breakpoints';
import { Promo } from '@/models/PromoModel';

import { Picture as IconPicture } from '@element-plus/icons-vue';
import Chip from '@/components/Chip.vue';
import ImageUploadModal from '@/components/ImageUploadModal.vue';
import toasts from '@/utils/toasts';

export default defineComponent({
  components: {
    Breadcrumb,
    Chip,
    IconPicture,
    ImageUploadModal,
  },
  data: () => ({
    loading: false,
    counterTimer: '',
    promoDetails: {} as unknown as Promo,
    loadingDetails: false,
  }),
  async mounted() {
    await this.getDetail();
  },
  computed: {
    ...mapGetters({
      promo: PromoGetters.GET_PROMO,
      errors: PromoGetters.GET_ACTION_ERRORS,
    }),
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },

    breadcrumbs() {
      return [
        {
          to: '/promos',
          text: 'Promos',
          current: false,
        },
        {
          to: `/promos/${this.$route.params.promo_id}`,
          text: 'Promo Details',
          current: true,
        },
      ];
    },

    address() {
      const addressLine =
        this.promo && this.promo?.address_line ? this.promo.address_line : '';
      const city = this.promo && this.promo?.city ? `, ${this.promo.city}` : '';
      const state =
        this.promo && this.promo?.state ? `, ${this.promo.state}` : '';
      const postCode =
        this.promo && this.promo?.post_code ? ` ${this.promo.post_code}` : '';
      const country =
        this.promo && this.promo?.country ? `, ${this.promo.country}` : '';

      return `${addressLine}${city}${state}${postCode}${country}`;
    },
  },
  methods: {
    ...mapActions({
      fetchPromo: PromoActions.FETCH_PROMO,
      uploadImage: PromoActions.UPLOAD_LOGO,
    }),

    getStatus(promo) {
      if (promo?.status === 'active') {
        return {
          type: 'success',
          text: 'ACtive',
          value: true,
          color: 'text-warning',
        };
      }

      return {
        type: 'warning',
        text: 'Inactive',
      };
    },

    async getDetail() {
      this.loading = true;

      await this.fetchPromo({ id: this.$route.params.promo_id }).then(() => {
        this.loading = false;
      });
    },

    onPageChanged() {
      console.log('test');
    },

    getAcronym(name = '') {
      return acronym(name);
    },

    handleRemove() {
      return true;
    },

    handleUploadImage(values) {
      const payload = {
        id: this.promo.id,
        image: values,
      };
      this.uploadImage(payload)
        .then(() => {
          toasts.success('Image successfully uploaded');
        })
        .catch(() => {
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },
  },
  watch: {
    async promo(values) {
      const promoData = await values;
      this.promoDetails = promoData;
    },
  },
});
